h2{
    color: white;
    text-align: center;
  }
  
  .mainContainer{
    margin: 10px;
  }
  
  .mainContainer .container{
    padding: 40px 0;
  }
  
  .mainContainer .container img{
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    text-align: center;
  }
  .slideImg{
    display: flex;
    overflow-x: scroll;
    overflow-y: hidden;
  }
  .containers{
    padding: 40px 0;
    padding: 40px 0;
    max-width: 140px;
    height: auto;
    margin-left: 5px;
    margin-right: 5px;
  }
  .containers img{
    align-items: center;
    display: flex;
    height: auto;
    justify-content: center;
    margin: 0px 16px;
    text-align: center;
    width: 100px;
  }