.logo{
    margin-bottom:50px;
}
.logoImg{
    width:23%;
}

@media only screen and (min-width: 1200px){
    .heightImag{
        height:84vh;
    }
    }

    @media only screen and (max-width: 1200px){
        .heightImag{
            height:80vh;
        }
        }



@media only screen and (max-width: 767px){
    .logo{
        margin-bottom:30px;
    }
    .logoImg{
        width:36%;
    }
    }