/* 
@media screen and (min-width: 767px) {
    .css-1jluznr,.css-b6lsxj-MuiButtonBase-root-MuiCardActionArea-root{
        display: flex !important;
        flex-direction: row-reverse;
        box-shadow: rgba(0, 0, 0, 0.15) 2px 2px 10px;

    }
    .cards .css-46bh2p-MuiCardContent-root,.cards .css-1qw96cp {
     width: 85%;
    }
    .cards .MuiCardMedia-img{
        width: 100px !important;
        height: 100px !important;
        border-radius: 4px;
        object-fit: cover;
    }
    .cards .css-1ri6ub7-MuiPaper-root-MuiCard-root,.css-1jluznr {
        max-width: 90% !important;
        margin: 0 auto;
        margin-top: 45px;
    }
  }
  .cards .css-1ri6ub7-MuiPaper-root-MuiCard-root,.css-1jluznr {
    margin: 0 auto;
    margin-top: 17px;
}*/
@media only screen and (min-width: 993px){
.CardFood {
    width: 87%;
    margin: 0 auto;
}
}
 
html[lang=ar] #cartPriceMenu {
    text-align: right ; 
}

 html[lang=ar] #cartNameMenu {
     text-align: right;
 }