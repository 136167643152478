
.container-dish {
width: 80vw;
margin:auto
}
.container-dish img{

        text-align: center;
       width: 100%;
        display: flex;
        margin: auto;
        margin-top: 31px;
        border-radius: 20px;
}


.container-dish h1.name {
    color:#0C5E5B ;
    margin-bottom: 0px;
    font-weight: 500 ;
}

.container-dish span.price {
    color: #0C5E5B;
    margin-top: 0px;
    font-size: 30px;
    font-weight: 500;
}

.container-dish p {
    color: #00000099;
   font-size: 20px;
   margin-bottom: 150px;
}

.container-dish.ar {
    text-align: right;
}
   
